@font-face {
  font-family: 'Futura New';
  src: url('FuturaNewLight-Reg.c3e4e517.eot');
  src: local('Futura New Light Reg'), local('FuturaNewLight-Reg'),
    url('FuturaNewLight-Reg.c3e4e517.eot#iefix') format('embedded-opentype'),
    url('FuturaNewLight-Reg.9d1d45c1.woff2') format('woff2'), url('FuturaNewLight-Reg.edbe4070.woff') format('woff'),
    url('FuturaNewLight-Reg.9526e345.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('FuturaNewBold-Reg.804ccc84.eot');
  src: local('Futura New Bold Reg'), local('FuturaNewBold-Reg'),
    url('FuturaNewBold-Reg.804ccc84.eot#iefix') format('embedded-opentype'),
    url('FuturaNewBold-Reg.7b608f5d.woff2') format('woff2'), url('FuturaNewBold-Reg.cd994ed8.woff') format('woff'),
    url('FuturaNewBold-Reg.ce513915.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura New';
  src: url('FuturaNewBook-Reg.08ed4795.eot');
  src: local('Futura New Book Reg'), local('FuturaNewBook-Reg'),
    url('FuturaNewBook-Reg.08ed4795.eot#iefix') format('embedded-opentype'),
    url('FuturaNewBook-Reg.24b9e46b.woff2') format('woff2'), url('FuturaNewBook-Reg.b7171b3b.woff') format('woff'),
    url('FuturaNewBook-Reg.f5a339aa.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  height: 100%;
  font-family: 'Futura New', 'Arial', sans-serif;
  -webkit-tap-highlight-color: transparent;
  margin: 0;
}

button:focus,
input:focus {
  outline: none;
}

p,
h1,
h2,
h3,
ol,
ul {
  margin: 0;
}

.overflow-hidden {
  overflow: hidden;
}

.body-noscroll {
  overflow: hidden;
}

.overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: 0.3s;
}

.overlay_active {
  opacity: 1;
  visibility: visible;
}

.nowrap {
  white-space: nowrap;
}

.owl-carousel:not(.owl-loaded) {
  opacity: 0;
  visibility: hidden;
  display: block;
}

.main__container {
  box-sizing: border-box;
  min-width: 320px;
  padding: 0 16px;
  margin-top: 111px;
}

@media (min-width: 768px) {
  .main__container {
    width: 720px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

@media (min-width: 1200px) {
  .main__container {
    width: 1144px;
  }
}

@media (min-width: 1920px) {
  .main__container {
    width: 1530px;
  }
}

.main__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main__logo {
  width: 125px;
  height: 108px;
  margin-bottom: 13px;
}

.main__logo img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main__logo-text {
  width: 134px;
  height: 25px;
  margin-bottom: 6px;
  text-align: center;
}

.main__logo-text img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.main__button {
  background: #2baa99;
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 193px;
  height: 41px;
  font-size: 16px;
  line-height: 41px;
  color: #fff;
  border: 0;
  font-weight: 500;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 320px;
  overflow-x: hidden;
  background: #f5f9fe;
}

.page__container {
  flex: 1 0 auto;
}

.page__content {
  height: 100%;
}

.page__footer {
  flex: 0 0 auto;
}

.input {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 193px;
  margin-bottom: 16px;
}

.input__input {
  font-size: 16px;
  line-height: 36px;
  height: 36px;
  padding: 0 8px;
  resize: none;
  outline: none;
  color: #000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f5f9fe;
  border: 1px solid #28afb0;
  box-sizing: border-box;
  border-radius: 8px;
}

.input__input::-moz-placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input:-ms-input-placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input::placeholder {
  color: #1f271b;
  opacity: 0.2;
}

.input__input::-ms-clear {
  display: none;
}

.input__input_error {
  border-color: #f00;
}

.input_error-text {
  display: none;
}

.input_error .input__input {
  border-color: #f00;
}

.input_error .input__label {
  display: flex;
}

.input_error .input_error-text {
  display: block;
  margin-left: 20px;
  color: #eb5757;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.05em;
  text-transform: none;
  white-space: nowrap;
  position: absolute;
  bottom: -16px;
}

.choice__info {
  position: fixed;
  z-index: 2000;
  bottom: 8px;
  left: 8px;
  right: 8px;
  top: auto;
}

.choice__items {
  padding: 8px 12px;
  background: #f5f9fe;
  border-radius: 16px;
  overflow: auto;
  max-height: 71vh;
}

.choice__item {
  background: #f5f9fe;
  height: 44px;
  line-height: 24px;
  font-size: 20px;
  color: #000000;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.choice__item:first-child {
  border-radius: 16px 16px 0 0;
}

.choice__item:last-child {
  border-radius: 0 0 16px 16px;
  margin-bottom: 0;
}

.choice__item + .choice__item {
  padding-top: 4px;
  position: relative;
}

.choice__item + .choice__item::before {
  content: '';
  height: 1px;
  width: 100%;
  background: radial-gradient(98.81% 11088500.3% at 0% 99.8%, rgba(40, 175, 176, 0.4) 0%, rgba(40, 175, 176, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
}

.choice__item svg {
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.choice__button {
  background: #f5f9fe;
  border-radius: 16px;
  height: 57px;
  line-height: 57px;
  text-align: center;
  font-size: 20px;
  color: #28afb0;
  border: 0;
  font-weight: 500;
  width: 100%;
  margin-top: 16px;
}

.market {
}

.market__header {
  position: fixed;
  width: 100%;
  margin: 0 auto;
  background: #f5f9fe;
  border-bottom: 1px solid #60d6a9;
  -webkit-box-shadow: 0px 5px 10px 2px rgba(174, 232, 191, 0.2);
  -moz-box-shadow: 0px 5px 10px 2px rgba(174, 232, 191, 0.2);
  box-shadow: 0px 5px 10px 2px rgba(174, 232, 191, 0.2);
}

.market__title {
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1f271b;
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.user__profile svg {
  margin-right: 9px;
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.market__corners_list-icon svg {
  margin-left: 9px;
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.headerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 65%;
}

.headerContainer span {
  text-align: center;
  max-width: 95%;
}

.headerContainer svg {
  margin-top: 5px;
  margin-left: 5px;
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.market__logo svg {
  stroke: #1f271b;
}

.market__date {
  font-size: 16px;
}

.market__orders_count {
  font-size: 18px;
}

.market__items {
  max-width: 359px;
  margin: 0 auto;
  padding-top: 114px;
}

.market__item {
  background: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
}

.market__item + .market__item {
  margin-top: 8px;
}

.market__col_right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}

.corner__list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 4px;
}

.order__status-time {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.order__status-small {
  font-size: 12px;
  line-height: 1;
  padding-right: 50px;
}

.order__status-waiting {
  color: #ffc700;
}

.order__status-confirmed {
  color: #60d6a9;
}

.order__status-refused {
  color: #c7c7d4;
}

.order__status-cooking {
  color: #ffa640;
}

.order__status-ready {
  color: #81fec6;
}

.order__status-waiting_on_counter {
  color: #717179;
}

.order__status-issued_courier {
  color: #717179;
}

.order__status-received {
  color: #00bc29;
}

.order__time {
  font-weight: 200;
  font-size: 12px;
  line-height: 1;
  color: #000000;
  /* margin-bottom: 2px; */
}

.market__row {
  display: flex;
  align-items: center;
}

.market__row + .market__row {
  margin-top: 4px;
}

.market__number {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 2px;
}

.discount_number {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #28afb0;
  margin-bottom: 2px;  
}

.market__tel {
  font-size: 10px;
  line-height: 14px;
  color: #19647e;
  opacity: 0.5;
  margin-bottom: 5px;
}

.market__tel a {
  margin-left: 5px;
  text-decoration: none;
  color: #19647e;
}

.market__sum {
  font-size: 14px;
  line-height: 20px;
  color: #28afb0;
  font-weight: 500;
}

.market__name {
  font-size: 14px;
  line-height: 14px;
  color: #19647e;
  margin-right: 8px;
}

.order__eat_place {
  font-size: 12px;
  line-height: 14px;
  color: #19647e;
  /* opacity: 0.7; */
  margin-bottom: 5px;
}

.market__status {
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  white-space: nowrap;
}

.market__status_waiting {
  background: #ffc700;
}

.market__status_confirmed {
  background: #60d6a9;
}

.market__status_refused {
  background: #c7c7d4;
}

.market__status_expects {
  background: #ffc700;
}

.market__status_cooking {
  background: #ffa640;
}

.market__status_ready {
  color: #19647e;
  background: #81fec6;
}

.market__status_received {
  background: #00bc29;
}

.market__status_issued_courier {
  background: #555a57;
}

.market__status_waiting_on_counter {
  background: #555a57;
}

.order {
  margin: 10px auto;
  max-width: 376px;
  padding-bottom: 72px;
}

.order__name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.block {
  display: block;
  color: #fff;
}

.order__name svg{
  width: 22px;
  height: 22px;
  display:inline-block;
}

.order__status {
  border-radius: 8px;
  padding: 2px 8px;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
}

.order__status_waiting {
  color: #19647e;
  background: #ffc700;
}

.order__status_confirmed {
  color: #fff;
  background: #60d6a9;
}

.order__status_refused {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_cooking {
  color: #19647e;
  background: #ffa640;
}

.order__status_ready {
  color: #19647e;
  background: #81fec6;
}

.order__status_readybtn {
  color: #fff;
  background: #00bc29;
  box-shadow: 2.3px 4.6px 4.6px hsl(0deg 0% 0% / 0.43);
}

.order__status_issued {
  color: #19647e;
  background: #c7c7d4;
}

.order__status_received {
  color: #00bc29;
  background: #fff;
}

.order__title {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 40px;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #1f271b;
  background-color: #fff;
  margin-bottom: 10px;
  max-width: 100%;
}

.order__title svg {
  fill: #1f271b;
  stroke: none;
  stroke-width: 2px;
  margin-left: 8px;
}

.order__logo svg {
  stroke: #1f271b;
  fill: none;
}

.order__table {
  max-width: 343px;
  margin: 46px auto 8px auto;
}

.order__row {
  display: flex;
  justify-content: space-between;
  line-height: 18px;
}

.order__row + .order__row {
  margin-top: 9px;
}

.order__col-name {
  font-size: 14px;
  color: #19647e;
  font-weight: 600;
}

.order__col-value {
  font-size: 16px;
  color: #000;
  text-align: right;
}

.order__client {
  color: #19647e;
  border-bottom: 1px solid;
}

.order__client a {
  color: #19647e;
  margin-left: 5px;
  text-decoration: none;
}

.order__total {
  max-width: 343px;
  margin: 8px auto 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #000;
  padding-top: 10px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.order__subtotal1 {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}

.order__subtotal2 {

}

.order__total-text {
  text-transform: uppercase;
  font-size: 14px;
}

.order__total-sum {
  font-size: 16px;
}

.order__item {
  padding: 8px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #e5e5e5;
}

.order__item + .order__item {
  margin-top: 8px;
}

.order__item_active .order__body {
  display: block;
}

.order__header {
  display: flex;
  align-items: center;
}

.order__header-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
}

.order__header-img img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.order__header-name {
  font-size: 16px;
  line-height: 20px;
  color: #19647e;
}

.order__header-btn {
  display: flex;
  margin-left: auto;
  font-size: 14px;
  height: 18px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 2px;
  border-radius: 9px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  border: none;
  min-width: 120px;
}

.order__body {
  display: none;
  margin-top: 8px;
}

.order__body-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order__num {
  font-size: 16px;

  color: #000000;
  width: 10px;
  margin-right: 12px;
  min-width: 10px;
  margin-bottom: 2px;
}

.order__dish {
  width: 170px;
  margin-right: 29px;
  font-size: 16px;

  color: #000000;
  margin-bottom: 2px;
  font-weight: 500;
}

.order__dish-extra {
  width: 170px;
  margin-right: 29px;
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  font-weight: 300;
}

.order__number {
  font-size: 14px;

  margin-bottom: 2px;
  text-align: right;
}

.order__number-extra {
  font-size: 14px;
  line-height: 15px;
  margin-bottom: 8px;
  text-align: right;
  font-weight: 300;
}

.order__number_bold {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
}

.order__sum {
  white-space: nowrap;
  font-size: 14px;
  margin-bottom: 2px;
}

.order__sum-extra {
  white-space: nowrap;
  font-size: 14px;
  line-height: 15px;
  font-weight: 300;
  margin-bottom: 2px;
}

.order__pricesum {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 120px;
}

.order__sum_md {
  font-size: 16px;
  line-height: 18px;
}

.order__itemprice {
  font-size: 12px;
  margin-right: 4px;
  color: #717179;
}

.order__body-item + .order__body-item {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.order__footer {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.order__button-wrap {
  background: #f5f9fe;
  box-shadow: 0 -2px 20px rgba(49, 67, 82, 0.18);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
}

.order__button {
  background: rgba(31, 29, 27, 0.1);
  box-shadow: -1px 3px 10px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  border: 0;
  height: 56px;
  line-height: 56px;
  margin: auto;
  max-width: 359px;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
  color: rgba(25, 100, 126, 0.4);
  display: flex;
  justify-content: center;
}

.order__button svg {
  display: none;
}

.order__button_accept {
  background: #00bc29;
  color: #fff;
  align-items: center;
}

.order__button_accept svg {
  display: block;
  fill: #fff;
  margin-right: 12px;
}

.order__button_primary {
  background: #28afb0;
  color: #fff;
}

.popup-page__content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  text-align: center;
}

.popup-page__body {
  background: #fff;
  border-radius: 16px;
  padding: 24px 43px;
  width: 220px;
  box-sizing: border-box;
}

.popup-page__name {
  font-size: 24px;
  white-space: nowrap;
  line-height: 34px;
  color: #000000;
  margin-bottom: 8px;
}

.popup-page__tel {
  text-decoration: none;
  white-space: nowrap;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
}

.button__div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-page__logout {
  display: flex;
  align-items: center;
  color: #000;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 16px;
  padding: 6px;
  border: none;
  border-radius: 4px;
  background: #fff;
}

.popup-page__logout svg {
  stroke: #000;
  fill: none;
  stroke-width: 2px;
  margin-right: 8px;
}

.loader {
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.fond {
  margin: auto;
}

.container_general {
  -webkit-animation: animball_two 1s infinite;
  -moz-animation: animball_two 1s infinite;
  -ms-animation: animball_two 1s infinite;
  animation: animball_two 1s infinite;
  width: 44px;
  height: 44px;
}

.container_mixt {
  width: 44px;
  height: 44px;
  position: absolute;
}

.ballcolor {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.ball_1,
.ball_2,
.ball_3,
.ball_4 {
  position: absolute;
  -webkit-animation: animball_one 1s infinite ease;
  -moz-animation: animball_one 1s infinite ease;
  -ms-animation: animball_one 1s infinite ease;
  animation: animball_one 1s infinite ease;
}

.ball_1 {
  background-color: #cb2025;
  top: 0;
  left: 0;
}

.ball_2 {
  background-color: #f8b334;
  top: 0;
  left: 24px;
}

.ball_3 {
  background-color: #00a096;
  top: 24px;
  left: 0;
}

.ball_4 {
  background-color: #97bf0d;
  top: 24px;
  left: 24px;
}

@-webkit-keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-moz-keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-ms-keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@keyframes animball_one {
  0% {
    position: absolute;
  }
  50% {
    top: 12px;
    left: 12px;
    position: absolute;
    opacity: 0.5;
  }
  100% {
    position: absolute;
  }
}

@-webkit-keyframes animball_two {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(1);
  }
}

@-moz-keyframes animball_two {
  0% {
    -moz-transform: rotate(0deg) scale(1);
  }
  50% {
    -moz-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -moz-transform: rotate(720deg) scale(1);
  }
}

@-ms-keyframes animball_two {
  0% {
    -ms-transform: rotate(0deg) scale(1);
  }
  50% {
    -ms-transform: rotate(360deg) scale(1.3);
  }
  100% {
    -ms-transform: rotate(720deg) scale(1);
  }
}

@keyframes animball_two {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(360deg) scale(1.3);
  }
  100% {
    transform: rotate(720deg) scale(1);
  }
}

.outletlist__item {
  background: #f5f9fe;
  height: 44px;
  line-height: 24px;
  font-size: 16px;
  color: #000000;
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}

.outletlist__item + .outletlist__item {
  margin-bottom: 4px;
  position: relative;
}

.outletlist__item + .outletlist__item::before {
  content: '';
  height: 1px;
  width: 100%;
  background: radial-gradient(98.81% 11088500.3% at 0% 99.8%, rgba(40, 175, 176, 0.4) 0%, rgba(40, 175, 176, 0) 100%);
  position: absolute;
  left: 0;
  top: 0;
}

.outletlist__item svg {
  margin-left: 9px;
  stroke: #19647e;
  fill: none;
  stroke-width: 2px;
}

.outlet_row_left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
  max-width: 70%;
}

/* оврыапл */

.outlet_row_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 42%;
  flex-basis: 30%;
  flex-grow: 1;
  flex-shrink: 1;
}

.outletlist__image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 8px;
}

.outletlist__image img {
  display: block;
  width: 40pxx;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.outletlist__title {
  line-height: 24px;
  font-size: 20px;
  color: #000000;
  margin-bottom: 4px;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-basis: 60%;
  flex-grow: 1;
  flex-shrink: 1;
}

.outlet_work_hours {
  color: #60d6a9;
}

.outlet_work_hours_off {
  color: red;
}

.market__status_string {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 12px;
  font-size: 16px;
  line-height: 22px;
  color: #60d6a9;
  text-align: center;
  margin-bottom: 10px;
}

.outlet__nan {
  text-align: center;
  color: red;
}

.outlet__on {
  text-align: center;
  color: #60d6a9;
}

.outlets__title {
  color: white;
  margin: auto;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.order__extra-pricesum {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 120px;
}

/* Стили для волн на экране логина */

.wave-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  height: 30rem;
  overflow: hidden;
}

.wave {
  position: absolute;
  top: 10rem;
  /* left: -50%; */
  width: 48rem;
  height: 48rem;
  background-color: #2c4f86;
  border-radius: 35%;
  animation-name: waves;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.wave + .one {
  animation-duration: 7000ms;
  opacity: 0.4;
}

.wave + .two {
  background-color: #70c7cb;
  animation-duration: 9000ms;
}

.wave + .three {
  background-color: #3f9671;
  animation-duration: 12000ms;
}

@keyframes waves {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* волны кончились */

/* эксперимент с карточкой рынка */

.markets__row {
  background: rgba(155, 194, 240, rgba(49, 67, 82, 0.18));
  /* border: 1px solid #e5e5e5; */
  border-radius: 8px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.markets__left,
.markets__right {
  background-color: #dee5e7;
  width: 40vw;
  height: 40vw;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
}

.order__weight {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
}

.order__position {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.yandex_order {
  box-shadow: inset 0 -3em 3em rgb(255 192 0 / 5%), 0 0 0 0px rgb(255 255 255), 0.3em 0.3em 1em rgb(255 192 0 / 10%);
  border: 1px solid	#FFD700;
}

.ninja_order {
  box-shadow: inset 0 -3em 3em rgb(60 179 13 / 5%), 0 0 0 0px rgb(255 255 255), 0.3em 0.3em 1em rgb(255 255 255 / 10%);
  border: 1px solid #20B2AA;
}

.order__itemnote {
  font-size: 12px;
  line-height: 18px;
  padding-left: 25px;
  color: #912707;
}

.card__weight {
  font-size: 10px;
  color: #19647e;
}

.card__img {
  border-radius: 50%;
  margin: auto;
}

.note__title {
  font-weight: bolder;
}

.distance_marker_danger {
  font-size: 10px;
  color: #eb5757;
}

.distance_marker_norm {
  font-size: 10px;
  color: #00a096;
}

/* эксперимент всё */


/*# sourceMappingURL=index.b5740bf8.css.map */
